import slugify from 'slugify';
import { IRoamingCountriesProps } from '../RoamingCountries';

export const FRoamingCountries = (entry: any): IRoamingCountriesProps => {
  const { identifier, inlineText, countries } = entry.fields;
  return {
    identifier: slugify(identifier),
    inlineText,
    countries: countries || []
  };
};
