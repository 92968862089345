import { MaybeStyledCSS, IMAGE_ASPECT_RATIO } from '@belong/types';
import { COLOURS, CONTAINER_MAX_WIDTHS, media, getAspectRatio, getPaddingTop, mediaMap } from '@belong/themes';
import styled, { css } from 'styled-components';
import { VideoContainer, PictureContainer } from '../../media';

type Layout = 'textImage' | 'imageText';
type Variation = 'small' | 'large';

const MIN_MEDIA_HEIGHT = '34.4rem';
const MIN_MEDIA_HEIGHT_LG = '50.4rem';

const CONTENT_PADDING_VERTICAL = {
  xs: '2.4rem',
  md: '3.2rem',
  lg: '5.6rem'
};

const CONTENT_PADDING_HORIZONTAL = {
  xs: '1.6rem',
  md: '3.2rem'
};

const RICH_TEXT_MARGIN = {
  xs: '2rem',
  md: '2.4rem',
  lg: '3.2rem'
};

export const DesktopOnly = styled.div`
  display: none;
  visibility: hidden;
  ${media('md')`
    display: block;
    visibility: visible; 
  `};
`;

export const MobileOnly = styled.div`
  ${media('md')`
    display: none;
    visibility: hidden;
  `};
`;

export const OuterWrapper = styled.div.attrs({ role: 'banner' })<{ layout: Layout }>`
  background-color: ${COLOURS.BLACK};
  display: flex;
  flex-direction: column-reverse;

  ${media('md')`
    flex-direction: ${({ layout }) => (layout === 'textImage' ? 'row' : 'row-reverse')}
  `}
`;

export const ContentWrapper = styled.div<{ layout: Layout }>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  ${mediaMap(
    CONTAINER_MAX_WIDTHS,
    (width: string) => css`
      width: calc(${width} / 2);
    `
  )}

  ${mediaMap(
    CONTENT_PADDING_VERTICAL,
    (padding: string) => css`
      padding-top: ${padding};
      padding-bottom: ${padding};
    `
  )}

  ${mediaMap(
    CONTENT_PADDING_HORIZONTAL,
    (padding: string) => css`
      padding-left: ${padding};
      padding-right: ${padding};
    `
  )}

  ${({ layout }) => {
    if (layout === 'textImage') {
      return media('lg')`
      padding-right: 5.6rem;
      padding-left: 3rem;
    `;
    }
    return media('lg')`
      padding-left: 5.6rem;
      padding-right: 3rem;
      `;
  }}

  ${media('md', 'lg')`
  ${({ layout }) =>
    layout === 'textImage'
      ? css`
          padding-left: 2.5rem;
        `
      : css`
          padding-right: 2.5rem;
        `}
    `}

  ${media('md')`
  ${({ layout }) =>
    layout === 'textImage'
      ? css`
          margin-left: auto;
        `
      : css`
          margin-right: auto;
        `}
    `}
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  ${mediaMap(
    RICH_TEXT_MARGIN,
    (margin: string) => css`
      margin-top: ${margin};
    `
  )}
  ${media('md')`
    width: auto;
  `}
`;

export const ImageWrapper = styled.div<{
  variation: Variation;
  hideImageOnSmall: boolean;
  ratio: IMAGE_ASPECT_RATIO;
}>`
  position: relative;
  display: flex;

  ${({ hideImageOnSmall }): MaybeStyledCSS => {
    if (hideImageOnSmall) {
      return css`
        display: none;
      `;
    }

    return undefined;
  }}

  ${({ variation, ratio }) => {
    if (variation === 'small') {
      return css`
        aspect-ratio: ${getAspectRatio(ratio)};
        overflow: hidden;

        @supports not (aspect-ratio: 16 / 9) {
          height: 0;
          padding-top: ${getPaddingTop(ratio)};
        }

        ${media('md')`
          @supports not (aspect-ratio: 16 / 9) {
            padding-top: 0;
          }
        `}
      `;
    }
    return css`
      height: 75vw;
    `;
  }}

  ${media('md')`
    display: block;
    width: 50%;
    height: auto;
    min-height: ${MIN_MEDIA_HEIGHT};
  `}

  ${({ variation }) => {
    if (variation !== 'small') {
      return media('lg')`
      min-height: ${MIN_MEDIA_HEIGHT_LG};
    `;
    }
  }}
`;

export const Image = styled(PictureContainer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${({ leftAlign }: { leftAlign: boolean }) => (leftAlign ? 'object-position: left center;' : '')}

  ${media('lg')`
    object-position: initial
  `}
`;

export const VideoWrapper = styled.div<{
  variation: Variation;
  hideImageOnSmall: boolean;
  ratio: IMAGE_ASPECT_RATIO;
}>`
  position: relative;
  aspect-ratio: ${({ ratio }) => getAspectRatio(ratio)};
  overflow: hidden;

  @supports not (aspect-ratio: 16 / 9) {
    height: 0;
    padding-top: ${({ ratio }) => getPaddingTop(ratio)};
  }

  ${({ hideImageOnSmall }): MaybeStyledCSS => {
    if (hideImageOnSmall) {
      return css`
        display: none;
      `;
    }

    return undefined;
  }}

  ${media('md')`
    padding-top: 0;
    display: block;
    width: 50%;
    height: auto;
    min-height: ${MIN_MEDIA_HEIGHT};
  `}

  ${({ variation }) => {
    if (variation !== 'small') {
      return media('lg')`
        min-height: ${MIN_MEDIA_HEIGHT_LG};
      `;
    }
  }}
`;

export const StyledVideoContainer = styled(VideoContainer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const CalloutWrapper = styled.div`
  display: flex;
  margin-bottom: 2.4rem;
`;
