import ReactDOM from 'react-dom';
import React from 'react';
import styled from 'styled-components';
import { Placement } from '@popperjs/core';
import { usePopper } from 'react-popper';
import { COLOURS } from '@belong/themes';
import { useIsFirstRender } from '@belong/react-hooks';
import { Copy } from '../../styles/Typography';

const DELAY_HIDE = 200;
const DELAY_SHOW = 200;
const ARROW_WIDTH = 12;
const SPACE_TO_ARROW = -4;

/**
 * 'dark' is a tooltip with black background and white foreground colours
 * 'light' is a tooltip with light orange background and black foreground colours.
 */
export type TooltipVariant = 'dark' | 'light';

const PopperContainer = styled.div<{ show?: boolean; variant?: TooltipVariant }>`
  display: ${p => (p.show ? 'block' : 'none')};
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  background-color: ${p => (p.variant === 'light' ? COLOURS.LIGHT_ORANGE : COLOURS.BLACK)};
  padding: 8px 12px;
  max-width: 50%;

  @media (min-width: ${p => p.theme.breakpoints.md}px) {
    max-width: 25%;
  }
  &[data-popper-placement^='top'] > .arrow {
    bottom: ${SPACE_TO_ARROW}px;
    &:after {
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    }
  }
  &[data-popper-placement^='bottom'] > .arrow {
    top: ${SPACE_TO_ARROW}px;
    &:after {
      box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.1);
    }
  }
  &[data-popper-placement^='left'] > .arrow {
    right: ${SPACE_TO_ARROW}px;
    &:after {
      box-shadow: 2px -2px 2px rgba(0, 0, 0, 0.1);
    }
  }
  &[data-popper-placement^='right'] > .arrow {
    left: ${SPACE_TO_ARROW}px;
    &:after {
      box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.1);
    }
  }
`;

const Arrow = styled.div<{ variant?: TooltipVariant }>`
  position: absolute;
  width: ${ARROW_WIDTH}px;
  height: ${ARROW_WIDTH}px;
  visibility: hidden;

  &:after {
    background-color: ${p => (p.variant === 'light' ? COLOURS.LIGHT_ORANGE : COLOURS.BLACK)};
    z-index: -1;
    position: absolute;
    width: ${ARROW_WIDTH}px;
    height: ${ARROW_WIDTH}px;
    visibility: visible;
    content: ' ';
    transform: rotate(45deg);
  }
`;

const TooltipChildWrapper = styled.span`
  display: inline-flex;
`;

const Portal = ({ children, domEl }: { children: React.ReactNode; domEl?: Element }) => {
  // This boolean allows us to know when it is safe to render the Portal,
  // avoiding hydration issues (it is safe after the initial client side render
  // which is after the useEffects are run).
  const isFirstRender = useIsFirstRender();
  return <>{isFirstRender ? children : ReactDOM.createPortal(children, domEl ?? document.body)}</>;
};

Portal.displayName = 'Portal';

export interface ITooltipProps {
  id?: string;
  children: React.ReactElement;
  title: string;
  variant?: TooltipVariant;
  placement?: Placement;
}

export const Tooltip: React.FC<ITooltipProps> = ({
  children,
  id,
  title,
  variant = 'dark',
  placement = 'bottom-start'
}) => {
  const triggerRef = React.useRef<HTMLDivElement>(null);
  const [popperRef, setPopperRef] = React.useState<any>(null);
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);
  const [visible, setVisible] = React.useState<boolean>(false);

  const timer = React.useRef<number>();
  React.useEffect(() => () => clearTimeout(timer.current), []);

  React.useEffect(() => {
    if (triggerRef.current == null) {
      return;
    }

    const hideTooltip = () => {
      clearTimeout(timer.current);
      timer.current = window.setTimeout(() => setVisible(false), DELAY_HIDE);
    };

    const showTooltip = () => {
      clearTimeout(timer.current);
      timer.current = window.setTimeout(() => setVisible(true), DELAY_SHOW);
    };

    triggerRef.current.addEventListener('mouseenter', showTooltip);
    triggerRef.current.addEventListener('mouseleave', hideTooltip);
    return () => {
      triggerRef?.current?.removeEventListener('mouseenter', showTooltip);
      triggerRef?.current?.removeEventListener('mouseleave', hideTooltip);
    };
  }, [triggerRef]);

  const { attributes, styles } = usePopper(triggerRef.current, popperRef, {
    placement,
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top', 'bottom', 'right']
        }
      },
      {
        name: 'arrow',
        options: {
          element: arrowRef,
          padding: ARROW_WIDTH
        }
      },
      {
        name: 'offset',
        options: {
          offset: [-(ARROW_WIDTH / 2), 4]
        }
      }
    ]
  });

  return (
    <>
      <TooltipChildWrapper className="tooltip-wrapper" ref={triggerRef}>
        {React.cloneElement(children, {
          'aria-label': title
        })}
      </TooltipChildWrapper>
      <Portal>
        <PopperContainer
          show={visible}
          variant={variant}
          role="tooltip"
          id={id}
          ref={setPopperRef}
          style={styles.popper}
          {...attributes.popper}
        >
          <Arrow ref={setArrowRef} variant={variant} style={styles.arrow} className="arrow" data-popper-arrow />
          <Copy as="span" hasColor={variant === 'light' ? 'black' : 'white'} variant="small">
            {title}
          </Copy>
        </PopperContainer>
      </Portal>
    </>
  );
};

Tooltip.displayName = 'Tooltip';
