import React from 'react';
import styled from 'styled-components';

import { COLOURS } from '@belong/themes';

import { Copy } from '../../styles/Typography/Copy';
import { Icon, ICON_NAMES } from '../../Icon';
import { Tooltip } from '../Tooltip';

const FlagContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 16px 0;
  gap: 8px 12px;
  @media (min-width: ${p => p.theme.breakpoints.xl}px) {
    gap: 8px 24px;
  }
`;

export interface IRoamingCountriesProps {
  identifier: string;
  inlineText: string;
  countries: string[];
  /** Be a good citizen in contentful, if used as a block element use div and if
   * inline embedded, use span.
   */
  as?: 'span' | 'div';
}

export const RoamingCountries = ({ identifier, inlineText, countries, as = 'span' }: IRoamingCountriesProps) => {
  return (
    <FlagContainer as={as} data-identifier={identifier}>
      {countries.map(countryName => (
        <Tooltip title={countryName} variant="light" key={countryName}>
          <Icon name={ICON_NAMES[countryNameToIconName(countryName)]} role="img" size="medium" />
        </Tooltip>
      ))}
      <Copy as="span" variant="small" hasColor={COLOURS.WHITE} id="additional-text">
        {inlineText}
      </Copy>
    </FlagContainer>
  );
};

RoamingCountries.displayName = 'RoamingCountries';

export const countryNameToIconName = (countryName: string) => {
  return `${countryName.replace(' ', '')}__Emoji`;
};
